import { api } from '@/helpers/api';
import { removeNamespaces, showMessage } from '@/helpers/utils';
import * as types from '@/store/types';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.FETCH_EVALUATION_MONITORING_EVENT]({ commit }, id) {
    try {
      const { data: response } = await api.get(`v2/monitoring/events?Id=${id}`);
      if (response.monitoringId) {
        const { monitoringId } = response;
        // Записываем monitoringId в store
        commit(localTypes.SET_EVALUATION_MONITORING_ID, monitoringId);

        const { data } = await api.get(`v2/monitoring/details?id=${monitoringId}`);
        return data;
      }
      commit(localTypes.SET_EVALUATION_MONITORING_ID, null);
      return null;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
  // получаем мониторинг по id что бы получить id шаблона мониторинга
  async [localTypes.FETCH_EVALUATION_MONITORING_ID](_, id) {
    try {
      const { data } = await api.get(`/v2/monitoring/${id}`);
      return data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
  // получанм общий checkList
  async [localTypes.FETCH_EVALUATION_CHECK_LIST](_, monitoringTemplateId = null) {
    try {
      const params = monitoringTemplateId ? { monitoringTemplateId } : {};
      const { data } = await api.get('/v3/evaluation-checkbox', { params });
      return data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },

  // получаем сохраненный checklist если он есть
  async [localTypes.FETCH_EVALUATION_SAVED_CHECK_LIST](_, id) {
    try {
      const { data } = await api.get(`/v3/evaluation-consultation?consultation_id=${id}`);
      return data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },

  // отправляем сохраненный checklist
  async [localTypes.POST_EVALUATION_CHECK_LIST](_, params) {
    try {
      const { data } = await api.post('/v3/evaluation-consultation', params);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Чеклист успешно сохранен!',
      });
      return data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },

  // получаем сохраненный коммент
  async [localTypes.FETCH_EVALUATION_CHECK_LIST_COMMENT](_, id) {
    try {
      const { data } = await api.get(`/v3/evaluation-consultation/comment?consultation_id=${id}`);
      return data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
  // сохраняем коммент
  async [localTypes.POST_EVALUATION_CHECK_LIST_COMMENT](_, params) {
    try {
      const { data } = await api.post('/v3/evaluation-consultation/comment', params);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Комментарий успешно сохранен!',
      });
      return data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
  async [localTypes.FETCH_EVALUATIONS_LIST]({ commit }, params = {}) {
    commit(localTypes.SET_EVALUATION_LOADING, true);
    try {
      const {
        query,
        take,
        skip,
        searchStringPatient,
        status,
        consultationId,
        monitoringTag,
      } = params;
      const requestParams = {
        query,
        take,
        skip,
        searchStringPatient,
        status,
        consultationId,
        monitoringTag,
      };
      const { data } = await api.get('v3/consultations/search', { params: requestParams });
      // Если это первая загрузка (skip = 0), перезаписываем список
      // Иначе - добавляем новые элементы
      const actionType = skip === 0
        ? localTypes.SET_EVALUATION_LIST
        : localTypes.APPEND_EVALUATION_LIST;
      commit(actionType, data);
      return data;
    } catch (error) {
      commit(localTypes.SET_EVALUATION_LOADING, false);
      throw error;
    } finally {
      commit(localTypes.SET_EVALUATION_LOADING, false);
    }
  },
  // получаем отчет
  async [localTypes.FETCH_EVALUATION_REPORT](_, id) {
    try {
      const response = await api.get(`/v3/evaluation-report?consultationId=${id}`, {
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'report.pdf';
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.warn(error);
      throw error;
    }
  },
};
