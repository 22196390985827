import { removeNamespaces } from '@/helpers/utils';
import * as types from '@/store/types';

const localTypes = removeNamespaces(types);

export default {
  // Установка списка опросов
  [localTypes.SET_EVALUATION_LIST](state, list) {
    state.evaluationsList = list;
  },
  // Добавление новых элементов к существующему списку
  [localTypes.APPEND_EVALUATION_LIST](state, newItems) {
    state.evaluationsList = [...state.evaluationsList, ...newItems];
  },
  // Установка состояния загрузки
  [localTypes.SET_EVALUATION_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  // Установка monitoringId
  [localTypes.SET_EVALUATION_MONITORING_ID](state, monitoringId) {
    state.monitoringId = monitoringId;
  },
};
