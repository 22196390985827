<template>
  <div
    ref="baseSelect"
    class="base-select"
  >
    <button
      class="select-wrapper"
      @click="toggleDropdown"
      @keydown.enter.prevent="toggleDropdown"
      tabindex="0"
    >
      <div class="selected-option">
        {{ selectedOptionLabel || placeholder }}
      </div>
      <IconArrow
        class="select-arrow"
        :class="{ 'rotate-180': isOpen }"
      />
    </button>

    <transition name="fade">
      <div
        v-if="isOpen"
        class="options-list"
      >
        <button
          v-for="option in filteredOptions"
          :key="option.id"
          class="option"
          @click="selectOption(option)"
        >
          {{ option.name }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import IconArrow from 'assets/images/arrow-dropdown.svg';

export default {
  name: 'BaseSelect',
  components: {
    IconArrow,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Выберите значение',
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      selectedOption: this.value || null, // Инициализация выбранного значения
    };
  },
  computed: {
    // Отфильтрованный список, исключающий выбранный элемент
    filteredOptions() {
      return this.selectedOption
        ? this.options.filter((option) => option.id !== this.selectedOption.id)
        : this.options;
    },
    selectedOptionLabel() {
      return this.selectedOption ? this.selectedOption.name : null;
    },
  },
  watch: {
    // Следим за изменением пропса value и обновляем состояние
    value(newValue) {
      this.selectedOption = newValue;
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.isOpen = false;
      this.$emit('change', option); // Генерируем событие при выборе
    },
    handleOutsideClick(event) {
      const { baseSelect } = this.$refs;
      if (baseSelect && !baseSelect.contains(event.target)) {
        this.isOpen = false; // Закрываем dropdown
      }
    },
  },
};
</script>

<style scoped lang="scss">
.base-select {
  position: relative;
  width: 100%;
  z-index: 3;
  background: #FFFFFF;
  color: #161E2F;
  font-size: 20px;
  border-radius: 8px;
  line-height: 24px;
  font-weight: 500;

  .select-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: left;
    align-items: center;
    box-shadow: 0px 4px 12px 2px rgba(22, 30, 47, 0.06);
    padding: 12px 24px;
    cursor: pointer;
    border-radius: 8px;
  }

  .selected-option {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .select-arrow {
    transition: transform 0.3s;
    min-width: 20px;
    &.rotate-180 {
      transform: rotate(180deg);
    }
    path {
      fill: #4071FF;
    }
  }

  .options-list {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    width: 100%;
    box-shadow: 0px 4px 12px 2px rgba(22, 30, 47, 0.06);
    background: #FFFFFF;
    border-radius: 8px;
    max-height: 200px;
    overflow-y: auto;
  }

  .option {
    padding: 12px 24px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    &:hover {
      color: #5B3AEF;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
