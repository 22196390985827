<template>
  <div class="base-search">
    <div class="base-search_input-block">
      <VIcon
        name="search"
        size="m"
        class="base-search_icon"
        color="#D0D2D5"
      />
      <input
        v-model="query"
        :placeholder="placeholder"
        class="base-search_input"
        :type="searchType"
        @keyup.enter="sendQuery"
      >
    </div>
    <VButton
      label="Поиск"
      size="l"
      @click="sendQuery"
    />
  </div>
</template>

<script>
import { VIcon, VButton } from '@doctis.front/doctis.designsystem';

export default {
  name: 'BaseSearch',
  components: {
    VIcon,
    VButton,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    searchType: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      query: '',
    };
  },
  methods: {
    sendQuery() {
      this.$emit('search', this.query);
    },
  },
};
</script>

<style lang="scss">
  .base-search {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    overflow: hidden;
    height: 48px;
    &_input-block {
      width: 100%;
      height: 100%;
      position: relative;
      border-radius: 8px;
      background: #ffffff;
      box-shadow: 0px 4px 12px 2px rgba(22, 30, 47, 0.06);
    }
    &_icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
      pointer-events: none;
    }
    &_input {
      width: 100%;
      border: none;
      border-radius: 8px;
      height: 100%;
      padding: 12px 24px 12px 56px;
      color: #161E2F;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      background: transparent;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid transparent;
      &--error {
        border-color: #dc3545;
        outline: #dc3545;
      }
      &:focus {
        outline-color: #277EFF;
      }
      &::placeholder {
        color: #7F838D;
      }
    }
  }
</style>
