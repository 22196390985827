import { removeNamespaces } from '@/helpers/utils';
import * as types from '@/store/types';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.GET_EVALUATIONS_LIST]: (state) => state.evaluationsList,
  [localTypes.GET_EVALUATIONS_LOADING]: (state) => state.isLoading,
  [localTypes.GET_EVALUATION_MONITORING_ID]: (state) => state.monitoringId,
};
